import images from "@assets/images";
import { DimensionContext } from "@src/DimensionProvider";
import { useAppSelector } from "@src/ducks/ducksHook";
import { useLobbyService } from "@src/ducks/hooks";
import { selectedGoldAllowStatus } from "@src/ducks/slices/games.slice";
import { ImageButton } from "components/button";
import React from "react";

const GameSwitch = () => {
  const { onSwitchCurrency } = useLobbyService();
  const gold = useAppSelector(selectedGoldAllowStatus);
  const { hudHeight } = React.useContext(DimensionContext);

  return (
    <ImageButton
      source={gold ? images.btn_switch_gold : images.btn_switch_silver}
      onPress={() => onSwitchCurrency(!gold)}
      style={[{ width: hudHeight * 0.845, height: hudHeight * 0.845 }]}
    />
  );
};

export default React.memo(GameSwitch);
