import images from "@assets/images";
import { DimensionContext } from "@src/DimensionProvider";
import { useAppSelector } from "@src/ducks/ducksHook";
import { useLobbyService } from "@src/ducks/hooks";
import { selectedChangeScreen } from "@src/ducks/slices/lobby.slice";
import { selectedProfileStatus } from "@src/ducks/slices/settings.slice";
import React from "react";
import { Image, StyleSheet, View } from "react-native";
import Animated, {
  Extrapolation,
  interpolate,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from "react-native-reanimated";
import ButtonSwitch from "./button-switch";
import DrawerItem from "./drawer-item";

function SideDrawer() {
  const animated = useSharedValue(1);
  const { onSwitchCategory } = useLobbyService();
  const { screen } = useAppSelector(selectedChangeScreen);
  const { baseWidth } = React.useContext(DimensionContext);
  const hasProfileOpen = useAppSelector(selectedProfileStatus);

  const onPress = () => {
    if(animated.value === 0){
      animated.value = withTiming(1, { duration: 500});
    }else{
      animated.value = withTiming(0, { duration: 500});
    }
  };

  const animateStyle = useAnimatedStyle(() => {
    const translateX = interpolate(
      animated.value,
      [0, 1],
      [-baseWidth * 0.088, 0],
      Extrapolation.CLAMP
    );

    return {
      transform: [{translateX}]
    };
  }, [baseWidth]);

  React.useEffect(() => {
    animated.value = withTiming(0, { duration: 500});
  },[hasProfileOpen]);

  React.useEffect(() => {
    if(screen === "gold-shop"){
      animated.value = withTiming(0, { duration: 500});
    }else if(screen === "mission"){
      animated.value = withTiming(0, { duration: 500});
    }
  },[screen]);

  return (
    <View pointerEvents="box-none" style={styles.container}>
      <Animated.View style={[styles.v_body, animateStyle]}>
        <Image style={styles.i_background} source={{uri: images["side-bar"]}} resizeMode="stretch" />
        <ButtonSwitch onPress={onPress} animated={animated} />
        <View style={styles.v_actions}>
          <DrawerItem source="my-favorite" onPress={() => onSwitchCategory(0)} />
          <DrawerItem source="slots" onPress={() => onSwitchCategory(1)} />
          <DrawerItem source="table" onPress={() => onSwitchCategory(2)} />
          <DrawerItem source="instant" onPress={() => onSwitchCategory("3,5")} />
          <DrawerItem source="action" onPress={() => onSwitchCategory(4)} /> 
          <DrawerItem source="puzzle" onPress={() => onSwitchCategory(8)} />
          <DrawerItem source="arcade" onPress={() => onSwitchCategory("6,7")} />
        </View>
      </Animated.View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: "11.9%", height: "100%",  
    justifyContent: "center",
    position: "absolute", zIndex: 11, left: 0
  },
  v_body: {width: "100%", height: "100%", justifyContent: "center" },
  i_background: {width: "100%", height: "100%", position: "absolute" },
  v_actions: {height: "82%", width: "50%", top: "-1%", left: "10%", alignItems: "center", justifyContent: "space-evenly" },
});

export default SideDrawer;