import { AUTH_PASSWORD, AUTH_USERNAME } from "@env";
import * as Types from "@src/ducks/types";
import * as api from "./request";

export const getAuth = () =>
  api.callPost("/authenticate", {
    username: AUTH_USERNAME,
    password: AUTH_PASSWORD,
  });
  
export const login = (params: Types.LoginInput) =>
  api.callPost("/api/v1/user/authenticate", { "partnerId": 2, ...params});
  // api.callPost("/api/v1/user/login", { "partnerId": 2, ...params}, accessToken);

export const refreshToken = (refreshToken: string) =>
  api.callPost("/api/v1/user/refresh-token", { refreshToken });

export const googleAuth = (params: any) =>
  api.get("https://www.googleapis.com/userinfo/v2/me", params);

export const loginWithGoogle = (params: any, accessToken: string) =>
  api.callPost("/api/v1/user?type=google&partnerId=2", params, accessToken);

export const facebookAuth = (accessToken: string) =>
  api.get(`https://graph.facebook.com/me?fields=email,first_name,last_name,gender&access_token=${accessToken}`, null);

export const loginWithFacebook = (params: any, accessToken: string) =>
  api.callPost("/api/v1/user?type=fb&partnerId=2", params, accessToken);

export const register = (params: Types.SignupInput) =>
  api.callPost("/api/v1/user?type=1&partnerId=2", params);

export const resendEmailVerification = (params: any, accessToken: string) =>
  api.callPost(`/api/v1/user/${params.id}/verification/resend-email`, {}, accessToken);

export const accountVerify = (params: any, accessToken: string) =>
  api.callPost("/api/v1/user/verify", params, accessToken);

export const forgotpassword = (email: string, accessToken: string) =>
  api.callPost("/api/v1/user/reset-password/send-email",{
    email,
    partnerId: 2,
  }, accessToken);

export const resetpassword = (params: Types.ResetPasswordInput, accessToken: string) =>
  api.callPost("/api/v1/user/reset-password/update", params, accessToken);
  // api.callPost(`/api/v1/user/password?password=${params.password}&email=${params.email}&reset_password=${params.otp}`, params, token);

// LOBBY CALL
export const fetchUserDetails = (accessToken: string) =>
  api.callGet("/api/v1/user/details", accessToken);

export const walletBalance = (params: Types.UserDetailValue, accessToken: string) =>
  api.callGet(`/api/v1/user/${params.userId}/wallet`, accessToken);

export const jadeEggAccumulation = (params: Types.UserDetailValue, accessToken: string) =>
  api.callGet(`/api/v1/user/${params.userId}/jade`, accessToken);

export const fetchUserRank = (params: Types.UserDetailValue, accessToken: string) =>
  api.callGet(`/api/v1/leaderboard/score/${params.userId}`, accessToken);

export const fetchRandomRewards = (params: Types.UserDetailValue, accessToken: string) =>
  api.callGet(`/api/v1/rewards/${params.userId}?rewardsId=2`, accessToken);

export const fetchMissions = (params: Types.UserDetailValue, accessToken: string) =>
  api.callGet(`/api/v1/user/${params.userId}/missions`, accessToken);

export const fetchAvatarTier = (params: Types.UserDetailValue, accessToken: string) =>
  api.callGet(`/api/v1/evo/avatartier/${params.userId}`, accessToken);

// GAMES
export const fetchGames = (params: string, accessToken: string) =>
  api.callGet( `/api/v1/game/list/web?${params}`, accessToken);

export const fetchFavouriteGames = (params: string, accessToken: string) =>
  api.callGet( `/api/v1/game/favourite?${params}`, accessToken);

export const fetchFeaturesGames = (params: string, accessToken: string) =>
  api.callGet( `/api/v1/game/featured?${params}`, accessToken);

export const fetchGameDetails = (params: string, accessToken: string) =>
  api.callGet( `/api/v1/game/?${params}`, accessToken);

export const fetchProducts = (accessToken: string) =>
  api.callGet( "/api/v1/products/get", accessToken);

export const checkDailyReward = async (params: Types.UserDetailValue, accessToken: string) => {
  const result = await api.callGet(`/api/v1/rewards/${params.userId}/isClaimed`, accessToken);

  if(result.data) {
    const server = await api.callGet("/utils/server", accessToken);
    return server.data;
  } else{
    return 0;
  }
};

export const check3hoursReward = (params: Types.UserDetailValue, accessToken: string) =>
  api.callGet(`/api/v1/user/${params.userId}/checkcollect`, accessToken);

export const fetchNotifications = (params: Types.UserDetailValue, accessToken: string) =>
  api.callGet(`/api/v1/${params.userId}/notifications`, accessToken);

export const collectDailyReward = (params: Types.DailyReward, accessToken: string) =>
  api.callPost(`/api/v1/rewards/${params.userId}?rewardName=${params.rewardName}`, {}, accessToken);

export const collect3HoursRewards = (params: Types.UserDetailValue, accessToken: string) =>
  api.callPost(`/api/v1/user/${params.userId}/collect`, {}, accessToken);

export const collectSigningBonus = (params: Types.UserDetailValue, accessToken: string) =>
  api.callPost(`/api/v1/rewards?userRewardsId=${params.userRewardsId}`, {}, accessToken);

export const echangeGoldToSilver = (params: Types.UserDetailValue, accessToken: string) =>
  api.callPost(`/api/v1/user/${params.userId}/exchange/goldcoin`, {}, accessToken);

export const collectMissionReward = (params: Types.UserDetailValue, accessToken: string) =>
  api.callPost(`/api/v1/user/${params.userId}/missions/claim-vault-reward`, {}, accessToken);

// SETTINGS
export const deactivateAccount = (params: Types.UserDetailValue, accessToken: string) =>
  api.callPost(`/api/v1/user/status?email=${params.email}&status=DEACTIVATE`, {}, accessToken);

export const changeAvatar = (params: Types.ChangeAvatar, token: string) =>
  api.callPost("/api/v1/evo/avatar", params, token);

// GAME MODE
export const fetchGameURL = (params: Types.GameMode, accessToken: string) =>
  api.callGet(`/api/v1/game/${params.gamecode}/redirect?userId=${params.userId}&gameTypeID=${params.gameTypeId}`, accessToken);

export const fetchGameURLCurrency = (params: Types.GameMode, accessToken: string) =>
  api.callGet(`/api/v1/game/${params.gamecode}/redirect?userId=${params.userId}&gameTypeID=${params.gameTypeId}&currency=${params.currency}`, accessToken);

export const fetchGameURLSwordPlay = (params: Types.GameMode, accessToken: string) =>
  api.callGet(`/api/v1/game/${params.gamecode}/redirect?userId=${params.userId}&platform=pc&timestamp=${params.timestamp}&gameTypeID=${params.gameTypeId}`, accessToken);

export const missionCompleted = (params: Types.GameMode, accessToken: string) =>
  api.callPost(`/api/v1/mission/${params.missionId}/status?gameCategoryID=${params.gameCategoryId}&userId=${params.userId}`, {}, accessToken);
  
export const swordplayPaymentInit = (params: Types.SwordPlayInit, accessToken: string) =>
  api.callPost("/api/v1/icasino/synapps/payment_init", params, accessToken);

export const swordplayPaymentCallback = (params: Types.SwordPlayCB, accessToken: string) =>
  api.callPost("/api/v1/icasino/synapps/payment_callback", params, accessToken);

// PURCHASE AT BUYSHOP
export const purchaseItem = (params: Types.Transaction, accessToken: string) =>
  api.callPost("/api/v1/payment/transaction", params, accessToken);

export const recordPurchase = (params: Types.Transaction, accessToken: string) =>
  api.callPost(`/api/v1/user/${params.userId}/wallet?productName=${params.purchaseItem}&paymentRefNo=${params.paymentRefNo}`, {}, accessToken);

export const recordPurchaseSubscription = (params: Types.Subscription, accessToken: string) =>
  api.callPost("/api/v1/subscription", params, accessToken);

export const recordPurchaseJade = (params: Types.UserDetailValue, accessToken: string) =>
  api.callPost(`/api/v1/jade/${params.userId}`, {}, accessToken);

export const apcopayCreditCard = (params: Types.Subscription, accessToken: string) =>
  api.callPost("/api/v1/apcopay/get_checkout_url", params, accessToken);


// BITPACE API CALL
export const bitpaceMerchantAuth = () => api.merchantAuth();

export const bitpaceMerchantCreateOrder = (params: any, accessToken: string) =>
  api.merchantPost("/fixed-deposit/url", params, accessToken);

// FETCH FAVORITE GAMES
export const fetchMyFavoriteGames = (userId: any, accessToken: string) =>
  api.callGet("/api/v1/user/getUserFavouriteGames?userId=" + userId, accessToken);

export const createMyFavoriteGames = (params: any, accessToken: string) =>
  api.callPost("/api/v1/user/createUserFavouriteGames", params, accessToken);

export const deleteMyFavoriteGames = (params: any, accessToken: string) =>
  api.callPost("/api/v1/user/deleteUserFavouriteGames", params, accessToken);

export const fetchTierUpgradeBonuses = (params: Types.UserDetailValue, accessToken: string) =>
  api.callGet( `/api/v1/user/${params.userId}/tier-bonuses`, accessToken);

export const claimTierUpgradeBonus = (params: Types.UserDetailValue, accessToken: string) =>
  api.callPost( `/api/v1/user/${params.userId}/tier-bonuses/claim`, params, accessToken);