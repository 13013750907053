import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../ducksHook";
import {
  authActions,
  selectAuthLoggingIn,
} from "../slices/auth.slice";

// Types
import { LoginInput, SignupInput } from "../types";
import { userActions } from "../slices/user.slice";

type AuthServiceOperators = {
  isLoading: boolean;
  onLogout: () => void;
  onResetLoading: () => void;
  onSignin: (params: LoginInput) => void;
  onSignInWithFB: (params: string) => void;
  onSignInWithGoogle: (params: string) => void;
  onUpdateProfile: (params: SignupInput) => void;
  onTermsAndConditionChange: (params: boolean) => void;
  updateUserRewards: (params: any) => void;
};

export const useAuthService = (): Readonly<AuthServiceOperators> => {
  const dispatch = useAppDispatch();

  return {
    isLoading: useAppSelector(selectAuthLoggingIn),
    onSignin: useCallback(
      (params: any) => {
        dispatch(authActions.loginRequest(params));
      },
      [dispatch]
    ),
    onSignInWithFB: useCallback(
      (params: string) => {
        dispatch(authActions.loginFBRequest(params));
      },
      [dispatch]
    ),
    onSignInWithGoogle: useCallback(
      (params: string) => {
        dispatch(authActions.loginGoogleRequest(params));
      }, [dispatch]
    ),
    onLogout: useCallback(() => {
      dispatch(authActions.logout());
    }, [dispatch]),
    onResetLoading: useCallback(() => {
      dispatch(authActions.resetLoading());
    }, [dispatch]),
    onUpdateProfile: useCallback((params: SignupInput) => {
      dispatch(userActions.updateProfile(params));
    }, [dispatch]),
    onTermsAndConditionChange: useCallback((params: boolean) => {
      dispatch(authActions.termsAndCondition(params));
    }, [dispatch]),
    updateUserRewards: useCallback((params: any) => {
      dispatch(userActions.updateUserRewards(params));
    }, [dispatch]),
  };
};

export default useAuthService;
