import images from "@assets/images";
import { useNavigation } from "@react-navigation/native";
import { DimensionContext } from "@src/DimensionProvider";
import { colors } from "@src/theme";
import { ImageButton06 } from "components/button";
import React from "react";
import { Image, ScrollView, Text, View } from "react-native";
import useStyles from "./styles.css";

const TierMoreInfo = () => {
  const styles = useStyles();
  const navigation = useNavigation();
  const { baseWidth, height, maxHeight } = React.useContext(DimensionContext);
  const WIDTH = baseWidth * 0.65;

  return (
    <View style={styles.container}>
      <ScrollView style={[styles.scrollview, { height }]}>
        <View style={[styles.v_info_more, { width: WIDTH, height: WIDTH * 1.6}]}>
          <Image
            source={{uri: images["tier-explanation-bg"]}}
            style={styles.imageBorderStyle}
            resizeMode="stretch"
          />
          <View style={[styles.v_content]}>
            <Text style={[styles.t_title, 
              { fontSize: baseWidth * 0.035, marginTop: "6%"}]}>TIER EXPLANATION
            </Text>
            <View style={{width: "80%", marginTop: "5%"}}>
              <Text style={[styles.t_regular, {fontFamily: "Roboto-Medium", fontSize: baseWidth * 0.018}]}>COPPER TIER - ENTRY LEVEL{"\n\n"}</Text>
              <Text style={[styles.t_regular, {fontSize: baseWidth * 0.018}]}>• limited games access - slots, table games and instant games.</Text>
              <Text style={[styles.t_regular, {fontSize: baseWidth * 0.018}]}>• Access to all app bonus features and reward features.{"\n\n"}</Text>

              <Text style={[styles.t_regular, {fontFamily: "Roboto-Medium", fontSize: baseWidth * 0.018}]}>BRONZE TIER - PLAYER SPEND OF USD$10+ IN A PERIOD OF 12 MONTHS{"\n\n"}</Text>
              <Text style={[styles.t_regular, {fontSize: baseWidth * 0.018}]}>• Receive 3,000,000 Bonus Silver Coins</Text>
              <Text style={[styles.t_regular, {fontSize: baseWidth * 0.018}]}>• Additional access to Prime games - slots, table games, RPG, arcade and instant games.</Text>
              <Text style={[styles.t_regular, {fontSize: baseWidth * 0.018}]}>• Access to all app bonus features and reward features.</Text>
              <Text style={[styles.t_regular, {fontSize: baseWidth * 0.018}]}>• Eligibility to earn GWz Gold Coins for every play.{"\n\n"}</Text>

              <Text style={[styles.t_regular, {fontFamily: "Roboto-Medium", fontSize: baseWidth * 0.018}]}>SILVER TIER - PLAYER SPEND OF USD$30+ IN A PERIOD OF 12 MONTHS{"\n\n"}</Text>
              <Text style={[styles.t_regular, {fontSize: baseWidth * 0.018}]}>• Receive 10,000,000 Bonus Coins</Text>
              <Text style={[styles.t_regular, {fontSize: baseWidth * 0.018}]}>• Access to Premium games - slots, table games, RPG, arcade and instant games.</Text>
              <Text style={[styles.t_regular, {fontSize: baseWidth * 0.018}]}>• Access to all app bonus features and reward features.</Text>
              <Text style={[styles.t_regular, {fontSize: baseWidth * 0.018}]}>• Eligibility to earn GWz Gold Coins for every play.{"\n\n"}</Text>

              <Text style={[styles.t_regular, {fontFamily: "Roboto-Medium", fontSize: baseWidth * 0.018}]}>GOLD TIER - PLAYER SPEND OF USD$50+ IN A PERIOD OF 12 MONTHS{"\n\n"}</Text>
              <Text style={[styles.t_regular, {fontSize: baseWidth * 0.018}]}>• Receive 17,000,000 Bonus Coins</Text>
              <Text style={[styles.t_regular, {fontSize: baseWidth * 0.018}]}>• All Access to Premium and VIP games in slots, table games, RPG, arcade and instant games, plus early access to upcoming new games.</Text>
              <Text style={[styles.t_regular, {fontSize: baseWidth * 0.018}]}>• Access to all app bonus features and reward features.</Text>
              <Text style={[styles.t_regular, {fontSize: baseWidth * 0.018}]}>• Eligibility to earn GWz Gold Coins for every play.{"\n\n"}</Text>
              <View style={{backgroundColor: colors.red7, paddingHorizontal: "5%", borderRadius: 50, alignSelf: "center", marginTop: "2%", paddingVertical: "0.6%"}}>
                <Text style={[styles.t_description, {fontSize: baseWidth * 0.018 }]}>• NOTE: Accounts Inactive for 12 months and over will{"\n"}automatically reset to Copper Tier.</Text>
              </View>
            </View>
          </View>
          <ImageButton06
            label="ЗАТВОРИ"
            onPress={() => navigation.goBack()}
            style={[styles.btnSubmit, { bottom: "6%", height: maxHeight * 0.07 }]}
            width={baseWidth * 0.12}
            height={baseWidth * 0.05}
            source={images["btn-big"]}
            labelStyle={{fontSize: baseWidth * 0.011}}
          />
        </View>
      </ScrollView>
    </View>
  );
};

export default TierMoreInfo;
