import { useCallback } from "react";
import { useAppDispatch } from "../ducksHook";
import { gamesActions } from "../slices/games.slice";
import { gmodeActions } from "../slices/gmode.slice";
import { lobbyActions } from "../slices/lobby.slice";
import * as Types from "../types";

type ServiceOperators = {
  onLobbyRequest: () => void;
  onFetchMissions: () => void;
  onChangeScreen: (params: Types.ChangeScreen) => void;
  onUpdateAvatarTier: (params: any) => void;
  onUpdateMissions: (params: string) => void;
  onUpdateNotifications: (params: any) => void;
  onPurchaseItem: (params: Types.Transaction) => void;
  onPurchaseReset: () => void;
  onChangeGames: (params: any) => void;
  onRequestBalance: () => void;
  onServerTime: (value: number) => void;
  on3HourTime: (value: any) => void;
  onPurchaseSuccess: (value: any) => void;
  setEnableHotdeals: () => void;

  // GAMES
  onSwitchCategory: (value: number | string) => void;
  onSwitchCurrency: (value: boolean) => void;
  onSwitchFavouriteGames: (value: any) => void;
};

export const useLobbyService = (): Readonly<ServiceOperators> => {
  const dispatch = useAppDispatch();

  return {
    onChangeScreen: useCallback(
      (params: Types.ChangeScreen) => dispatch(lobbyActions.changeScreen(params)),
      [dispatch]
    ),
    onLobbyRequest: useCallback(
      () => dispatch(lobbyActions.lobbyRequest()),
      [dispatch]
    ),
    onUpdateAvatarTier: useCallback(
      (params: any) => dispatch(lobbyActions.avatarTier(params)),
      [dispatch]
    ),
    onFetchMissions: useCallback(
      () => dispatch(gmodeActions.missionRequest()),
      [dispatch]
    ),
    onUpdateMissions: useCallback(
      (params: string) =>  dispatch(lobbyActions.updateMissions(params)),
      [dispatch]
    ),
    onUpdateNotifications: useCallback(
      (params) => dispatch(lobbyActions.updateNotifications(params)),
      [dispatch]
    ),
    onPurchaseItem: useCallback(
      (params) => dispatch(lobbyActions.purchaseRequest({...params})),
      [dispatch]
    ),
    onPurchaseReset: useCallback(
      () => dispatch(lobbyActions.purchaseFailure()),
      [dispatch]
    ),
    onChangeGames: useCallback(
      (params) => dispatch(lobbyActions.goldGames(params)),
      [dispatch]
    ),
    onRequestBalance: useCallback(
      () => dispatch(lobbyActions.balanceRequest()),
      [dispatch]
    ),
    onServerTime: useCallback(
      (value) => dispatch(lobbyActions.serverTime(value)),
      [dispatch]
    ),
    on3HourTime: useCallback(
      (value) => dispatch(lobbyActions.hourlyReward(value)),
      [dispatch]
    ),
    onPurchaseSuccess: useCallback(
      (value) => dispatch(lobbyActions.purchaseSuccess(value)),
      [dispatch]
    ),
    setEnableHotdeals: useCallback(() => dispatch(lobbyActions.setEnableHotdeals()),
      [dispatch]
    ),

    // GAMES
    onSwitchCategory: useCallback((id) => dispatch(gamesActions.fetchingGames(id)),
      [dispatch]
    ),
    onSwitchCurrency: useCallback((value) => dispatch(gamesActions.fetchGoldGames(value)),
      [dispatch]
    ),
    onSwitchFavouriteGames: useCallback((item) => dispatch(gamesActions.fetchFavouriteGames(item)),
      [dispatch]
    ),
  };
};

export default useLobbyService;