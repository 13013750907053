import images from "@assets/images";
import SVGDrawer from "@assets/svg/side-drawer";
import { DimensionContext } from "@src/DimensionProvider";
import { ImageButton05 } from "components/button";
import React from "react";
import { Image } from "react-native";

type Props = {
  onPress: () => void;
  source: any;
}

function DrawerItem(props: Props) {
  const { baseWidth } = React.useContext(DimensionContext);
  const RenderComingSoon = React.useMemo(() => {
    if(["slots", "table", "puzzle"].includes(props.source)){
      return <Image source={{uri: images["coming-soon"]}} 
        style={{width: "100%", height: "100%", position: "absolute"}} resizeMode="stretch" />;
    }

    return null;
  },[props.source]);

  return (
    <ImageButton05 onPress={props.onPress} >
      <SVGDrawer name={props.source} width={baseWidth * 0.05} height={baseWidth * 0.05}/>
      {RenderComingSoon}
    </ImageButton05>
  );
}

export default DrawerItem;